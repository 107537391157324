:root {
    --spacing-unit: 10px;
    --logo-color: #273992;
    --text-color: #222;
    --medium-color: #333;
    --grey-color: #444;
    --link-border: 1px solid rgba(34, 34, 34, 0.2);
    --header-font-family: europa, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    --body-font-family: chaparral-pro, serif;
    /* font-size: 1.1em; */
}

* {
    margin: 0;
    padding: 0;
    color: var(--text-color);
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--header-font-family);
}

p {
    font-family: var(--body-font-family);
    font-size: 1.1em;
    line-height: 1.6em;
    color: var(--medium-color);
}

i {
    color: currentColor;
}

p b {
    font-family: var(--header-font-family);
    color: currentColor;
}

a {
    color: currentColor;
    text-decoration: none;
    border-bottom: var(--link-border);
}

button, a {
    transition: opacity 0.3s;
}

a:hover, button:hover {
    opacity: 0.6;
}

header, footer {
    background: #EEE;
    padding: var(--spacing-unit);
}

header {
    margin-top: 0;
    margin-bottom: var(--spacing-unit);
    border-bottom: 10px solid #E6E6E6;
}

footer {
    margin-bottom: 0;
    margin-top: var(--spacing-unit);
    border-top: 10px solid #E6E6E6;
}

.content {
    max-width: min(100%, 800px);
    margin: var(--spacing-unit) auto;
}

.beyond-wordplay-link {
    border: none;
}

.beyond-wordplay-logo {
    width: 10em;
}

.headers {
    font-size: 1.5em;
    text-align: center;
    margin: calc(2 * var(--spacing-unit)) 0;
}

.headers h3 {
    color: var(--grey-color);
    line-height: 1em;
    font-weight: normal;
    font-style: italic;
}

.vowel-filters {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: var(--spacing-unit);
}

.bottom-vowel-filters {
    margin-bottom: calc(2 * var(--spacing-unit));

}

.vowel-filters-label {
    color: var(--grey-color);
    display: inline;
    font-weight: normal;
    /* font-style: italic; */
    font-size: 1.1em;
    margin-right: var(--spacing-unit);
}

.vowel-button {
    cursor: pointer;
    display: inline-block;
    margin: 0 var(--spacing-unit);
    font-family: europa, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    border: none;
    font-weight: normal;
    color: white;
    font-size: 1.5em;
    width: 1em;
    color: var(--text-color);
    border-bottom: var(--link-border);
    line-height: 1em;
}

.active-vowel-button {
    font-weight: bold;
}

.humans {
    position: relative;
    margin: var(--spacing-unit);
}

.known-human-being {
    display: flex;
    flex-flow: row wrap;
    padding: calc(2 * var(--spacing-unit));
    padding-bottom: var(--spacing-unit);
    margin: 0 auto;
}

.known-human-being:not(:last-child) {
    border-bottom: 1px solid lightgrey;
}

.human-name {
    line-height: 1.8em;
}

.human-subtitle {
    /* margin-top: calc(var(--spacing-unit) * 0.5); */
    margin-bottom: calc(var(--spacing-unit) * 1.5);
    font-weight: normal;
    font-size: 1.1em;
    /* font-style: italic; */
    color: var(--grey-color);
}

.human-image {
    min-width: 150px;
    height: 200px;
    margin-right: calc(2 * var(--spacing-unit));
    box-shadow: 5px 5px var(--text-color);
}

.human-sidebar {
    display: flex;
}

.human-header {
    /* flex: 1 0 100%; */
    min-width: 7em;
}

.favourite {
    flex: 1 0 100%;
    display: flex;
    margin-bottom: var(--spacing-unit);
}

.human-vowels {
    font-size: 1.8em;
}

.just-a-mention {
    margin-left: 7em;
}

.just-a-mention-header {
    margin-top: calc(2 * var(--spacing-unit));
    margin-bottom: var(--spacing-unit);
    font-weight: normal;
    font-style: italic;
    color: var(--grey-color);
    flex: 1 0 100%;
}

.just-a-mention-body {
    display: flex;
    flex-flow: row wrap;
}

.mentioned-image {
    min-width: 80px;
    height: 80px;
    box-shadow: 3px 3px var(--text-color);
}

.mentioned-subtitle {
    font-weight: normal;
    color: var(--grey-color);
}

.mentioned-human {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    margin-right: var(--spacing-unit);
    margin-bottom: var(--spacing-unit);
}

.mentioned-human > * {
    margin-right: var(--spacing-unit);
}

.mentioned-image {
    margin-bottom: var(--spacing-unit);
}

.mentioned-info {
    text-align: center;
    max-width: 100px;
}

@media all and (max-width: 600px) {
    .known-human-being {
        padding: calc(1 * var(--spacing-unit));
    }

    .human-header {
        margin-bottom: var(--spacing-unit);
    }

    .human-sidebar {
        display: block;
    }

    .headers {
        font-size: 1em;
    }

    .human-name {
        font-size: 1.3em;
    }

    .just-a-mention {
        margin-left: 0;
    }
}

@media all and (max-width: 350px) {
    .vowel-filters-label {
        display: block;
        margin-bottom: var(--spacing-unit);
    }
}